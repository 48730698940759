import { render, staticRenderFns } from "./widget.vue?vue&type=template&id=845dfab4&scoped=true&"
import script from "./widget.vue?vue&type=script&lang=js&"
export * from "./widget.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "845dfab4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TemplatesLayoutWidget: require('/home/ticketshoplv/public_html/jarmolenka.tour.tickets/components/templates/layout/widget.vue').default})

import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _168bdc72 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _228bcebb = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _df96028c = () => interopDefault(import('../pages/categories/index.vue' /* webpackChunkName: "pages/categories/index" */))
const _37b4a1c4 = () => interopDefault(import('../pages/cookies.vue' /* webpackChunkName: "pages/cookies" */))
const _8b730a12 = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _833c9644 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _5ae5ef65 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _29150bfe = () => interopDefault(import('../pages/payment/index.vue' /* webpackChunkName: "pages/payment/index" */))
const _656473e1 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _aaf06184 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _191aa286 = () => interopDefault(import('../pages/restore/index.vue' /* webpackChunkName: "pages/restore/index" */))
const _4e470083 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _7f268b3c = () => interopDefault(import('../pages/widget/index.vue' /* webpackChunkName: "pages/widget/index" */))
const _b8dea494 = () => interopDefault(import('../pages/profile/tickets.vue' /* webpackChunkName: "pages/profile/tickets" */))
const _040d968c = () => interopDefault(import('../pages/widget/description.vue' /* webpackChunkName: "pages/widget/description" */))
const _631a3a0a = () => interopDefault(import('../pages/categories/_cid/index.vue' /* webpackChunkName: "pages/categories/_cid/index" */))
const _8c982e22 = () => interopDefault(import('../pages/events/_eid/index.vue' /* webpackChunkName: "pages/events/_eid/index" */))
const _37e0464c = () => interopDefault(import('../pages/groups/_id.vue' /* webpackChunkName: "pages/groups/_id" */))
const _67715799 = () => interopDefault(import('../pages/payment/_hash.vue' /* webpackChunkName: "pages/payment/_hash" */))
const _8dd086c8 = () => interopDefault(import('../pages/restore/_key.vue' /* webpackChunkName: "pages/restore/_key" */))
const _21d71744 = () => interopDefault(import('../pages/events/_eid/tickets.vue' /* webpackChunkName: "pages/events/_eid/tickets" */))
const _19f91f02 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/lt",
    component: _168bdc72,
    name: "index___lt"
  }, {
    path: "/lt/cart",
    component: _228bcebb,
    name: "cart___lt"
  }, {
    path: "/lt/categories",
    component: _df96028c,
    name: "categories___lt"
  }, {
    path: "/lt/cookies",
    component: _37b4a1c4,
    name: "cookies___lt"
  }, {
    path: "/lt/events",
    component: _8b730a12,
    name: "events___lt"
  }, {
    path: "/lt/login",
    component: _833c9644,
    name: "login___lt"
  }, {
    path: "/lt/logout",
    component: _5ae5ef65,
    name: "logout___lt"
  }, {
    path: "/lt/payment",
    component: _29150bfe,
    name: "payment___lt"
  }, {
    path: "/lt/profile",
    component: _656473e1,
    name: "profile___lt"
  }, {
    path: "/lt/register",
    component: _aaf06184,
    name: "register___lt"
  }, {
    path: "/lt/restore",
    component: _191aa286,
    name: "restore___lt"
  }, {
    path: "/lt/search",
    component: _4e470083,
    name: "search___lt"
  }, {
    path: "/lt/widget",
    component: _7f268b3c,
    name: "widget___lt"
  }, {
    path: "/lt/profile/tickets",
    component: _b8dea494,
    name: "profile-tickets___lt"
  }, {
    path: "/lt/widget/description",
    component: _040d968c,
    name: "widget-description___lt"
  }, {
    path: "/lt/categories/:cid",
    component: _631a3a0a,
    name: "categories-cid___lt"
  }, {
    path: "/lt/events/:eid",
    component: _8c982e22,
    name: "events-eid___lt"
  }, {
    path: "/lt/groups/:id?",
    component: _37e0464c,
    name: "groups-id___lt"
  }, {
    path: "/lt/payment/:hash",
    component: _67715799,
    name: "payment-hash___lt"
  }, {
    path: "/lt/restore/:key",
    component: _8dd086c8,
    name: "restore-key___lt"
  }, {
    path: "/lt/events/:eid/tickets",
    component: _21d71744,
    name: "events-eid-tickets___lt"
  }, {
    path: "/lt/:slug",
    component: _19f91f02,
    name: "slug___lt"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

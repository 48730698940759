import locale77a7dd39 from '../../lang/lt.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"lt","silentTranslationWarn":true,"numberFormats":{"lt":{"currency":{"style":"currency","currency":"EUR","currencyDisplay":"symbol"}}},"dateTimeFormats":{"lt":{"tickets":{"year":"numeric","month":"2-digit","day":"2-digit","hour":"2-digit","minute":"2-digit","hour12":false},"short":{"year":"numeric","month":"short","day":"numeric"},"shortExtended":{"year":"numeric","month":"long","day":"numeric"},"long":{"year":"numeric","month":"long","day":"numeric","weekday":"long","hour":"numeric","minute":"numeric"},"longer":{"year":"numeric","month":"long","day":"numeric","hour":"numeric","minute":"numeric"},"fullDay":{"weekday":"long"},"newsHeading":{"month":"short","day":"numeric"},"shortMonth":{"month":"short"}}}},
  vueI18nLoader: false,
  locales: [{"code":"lt","iso":"lt-LT","file":"lt.js"}],
  defaultLocale: "lt",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix",
  lazy: false,
  langDir: "/home/ticketshoplv/public_html/jarmolenka.tour.tickets/lang",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  seo: false,
  baseUrl: "https://widget.ticketshop.store",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"lt","iso":"lt-LT","file":"lt.js"}],
  localeCodes: ["lt"],
}

export const localeMessages = {
  'lt.js': () => Promise.resolve(locale77a7dd39),
}
